import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Seo from "src/components/Seo";
import PlaylistAnnounces from "src/routes/PlaylistAnnounces";

const Medialibrary = (props) => {
  const data = useStaticQuery(query);
  return (
    <>
      <Seo pageTitle="Медиатека" />
      <PlaylistAnnounces data={data.playlistsAnnounces.data} />
    </>
  );
};

const query = graphql`
  query {
    playlistsAnnounces {
      data {
        _id
        announceDescription
        goodId
        id
        img
        transcribeName
        name
        order
      }
    }
  }
`;

export default Medialibrary;
