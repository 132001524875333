import React from "react";
import PageHeader from "src/components/PageHeader";
import { Playlist, PlaylistAnnounce } from "src/types";
import Announce from "../../components/Announce";
import styles from "./announcesStyles.module.css";
import FirstAnnounce from "./FirstAnnounce";

type Props = {
  announces: PlaylistAnnounce[];
};

const Announces = (props: Props) => {
  if (props.announces.length === 1) {
    return (
      <div>
        <FirstAnnounce
          announce={props.announces[0]}
          link={createLink(props.announces[0])}
        />
      </div>
    );
  }

  return (
    <div>
      <PageHeader text="Медиатека" />
      <FirstAnnounce
        announce={props.announces[0]}
        link={createLink(props.announces[0])}
      />
      <div className={styles.mappedAnnounces}>
        {props.announces.map((curr, i) => {
          if (i === 0) {
            return null;
          }

          return (
            <Announce
              key={curr._id + i}
              announce={curr}
              link={createLink(curr)}
            />
          );
        })}
      </div>
    </div>
  );
};

const createLink = (pl: PlaylistAnnounce): string => {
  if (pl.is19PPM) {
    return "/potential-project-market";
  }
  return `/playlist/${pl.transcribeName}`;
};

export default Announces;
