import { Link } from "gatsby";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import Typography from "src/components/Typography";
import { PlaylistAnnounce } from "src/types";
import styles from "./FirstAnnounce.module.css";

type Props = {
  announce: PlaylistAnnounce;
  link: string;
};

const FirstAnnounce = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <Link to={props.link} className={styles.imgWrapper}>
        <img src={props.announce.img} alt="Обложка плейлиста" />
      </Link>
      <div className={styles.txtWrapper}>
        <Typography type="h3">
          <h3>{props.announce.name}</h3>
        </Typography>
        <Typography type="text">
          {props.announce.announceDescription}
        </Typography>
        <div>
          <Link to={props.link}>
            <ArrowButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FirstAnnounce;
