import * as React from "react";
import Api from "src/api";
import { PlaylistAnnounce } from "src/types";
import Announces from "./Announces";

type State = {
  announces: PlaylistAnnounce[];
};

type Props = {
  data: PlaylistAnnounce[];
};

class PlaylistsContainer extends React.Component<Props, State> {
  public state = {
    announces: this.props.data || [],
  };

  public async componentDidMount() {
    const res = await Api.getPlaylistsAnnounces();
    this.setState({
      announces: res.data,
    });
  }

  public render() {
    if (!this.props.data?.length && !this.state.announces?.length) {
      return null;
    }
    return <Announces announces={this.state.announces || this.props.data} />;
  }
}

export default PlaylistsContainer;
